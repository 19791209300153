import { Component, OnInit, Input, PLATFORM_ID, Inject } from '@angular/core';
import { AppSettings } from '../../../shared/app.settings';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { CommonBindingDataService } from 'src/app/modules/shared/services/common-binding-data.service';


@Component({
  selector: 'app-campaign-profile',
  templateUrl: './campaign-profile.component.html'
})
export class CampaignProfileComponent implements OnInit {
  @Input() profile :any;
  value = 100;
  videoUrl = '';
  ios = true;
  deviceMode = true;
  active = AppSettings.ACTIVE_CAMPAIGN;
  message = [];
  linkCopied = false;
  isPlatformBrowser: any;
  shareSms = false;
  description: any;
  daysLeft = '';


  constructor(@Inject(PLATFORM_ID) platformId: Object,
private commonService: CommonBindingDataService) {
    this.isPlatformBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (this.isPlatformBrowser) {
      this.deviceMode = this.detectmob();
    }
  }

  ngOnChanges() {
    if (this.profile) {
      const endDatetime = this.profile.endDatetime;
      this.daysLeft = this.commonService.calculateDaysLeft(
        endDatetime,
        this.profile.campaignStatus
      );
    }
  }

  shareOnFacebook(facebookURL) {
    const url = encodeURIComponent(facebookURL);
 
    const fbAppUrl = `fb://facewebmodal/f?href=${url}`;
    const fbWebUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
 
    // Detect mobile platform
    const isAndroid = /android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
 
    if (isAndroid || isIOS) {
      // Try opening the Facebook app
      window.location.href = fbAppUrl;
 
      // If the app is not installed, fallback to web after 500ms for iOS (reduce the time to make it more responsive)
      const timeoutDuration = isIOS ? 500 : 1000;
      setTimeout(() => {
        window.open(fbWebUrl, '_blank');
      }, timeoutDuration);
    } else {
      // Directly open in browser for desktops
      window.open(fbWebUrl, '_blank');
    }
}

  detectmob() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }



  linkCopiedMsg() {
    this.linkCopied = true;
    setTimeout(() => {
      this.linkCopied = false;
    }, 2000);
  }

  getDescription() {
    return `Please support our fundraiser for ${this.profile.name}! Click on the below link to contribute now.`;
  }

  getSMSBody(shareUrl:any) {
    if (this.isPlatformBrowser) {
      const userAgent = navigator.userAgent || navigator.vendor;
      const a: any = document.createElement('a');
      const link = encodeURIComponent(shareUrl);

      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
        a.href = `sms:&body=Please support our fundraiser for ${this.profile.name}! Click on the below link to contribute now. ${link}`;
        a.click();
      } else {
        a.href = `sms:?body=Please support our fundraiser for ${this.profile.name}! Click on the below link to contribute now. ${link}`;
        a.click();
      }
    }
  }
}

