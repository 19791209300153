import {
  Component,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { UserCommonService } from '../../../shared/services/user-common.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { CommonMessageTransferService } from '../../../shared/services/common-message-transfer.service';
import { ErrorDto } from '../../../shared/models/error-dto';
import { MessageService } from 'primeng/api';
declare var stripe: any;
declare var elements: any;

@Component({
  selector: 'app-payment-stripe',
  templateUrl: './payment-stripe.component.html',
  styleUrls: ['./payment-stripe.component.scss']
})
export class PaymentStripeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cardInfo')
  cardInfo!: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: any;
  payment: any;
  userId : any;
  campaignId : any;
  mediaType : any;
  paymentProcessing = false;
  errorMessage :any;
  paymentFailed = false;
  restAPIGeneralSubscription: any;
  serviceCharges: any;
  totalAmount: any;
  subTotalAmount: any;
  refereId: any;
  // The items the customer wants to buy
  elements;
  isConfirmedRefresh: boolean = false;

  prButton: any;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private commonMessageTransferService: CommonMessageTransferService,
    private commonBindingService: CommonBindingDataService,
    private commonService: UserCommonService,
    private messageService: MessageService) {
      this.serviceCharges = this.route.snapshot.queryParamMap.get('serviceCharges');
    }

    @HostListener('window:beforeunload', ['$event'])
    handleBeforeUnload(event: BeforeUnloadEvent){
      if (!this.isConfirmedRefresh) {
        event.preventDefault();        
      }
    }

  // Listen for keyboard events like F5 or Ctrl+R
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'F5' || (event.ctrlKey && event.key === 'r')) {
      event.preventDefault();
      this.showRefreshConfirmation();
    }
  }

   // Method to show a custom refresh confirmation dialog
   showRefreshConfirmation() {
    const userConfirmed = confirm(this.commonBindingService.getLabel('lbl_do_not_refresh_page'));

    if (userConfirmed) {
      this.isConfirmedRefresh = true;
      this.router.navigate(['/donation/' + this.campaignId], {
        queryParams: {
          userId: this.userId,
          mediaType: this.mediaType
        }
      });
    } else {
      this.isConfirmedRefresh = false;
    }
  }

  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.campaignId = this.route.snapshot.params['c'];
    this.mediaType = this.route.snapshot.queryParamMap.get('mediaType');
    this.errorMessage = this.commonBindingService.getLabel('err_general_msg');
    if (this.commonService.donor) {
      this.payment = this.commonService.donor;
      this.subTotalAmount = parseFloat(this.payment.amount) + parseFloat(this.serviceCharges);
      this.totalAmount = parseFloat(this.subTotalAmount).toFixed(2);
    }

    this.restAPIGeneralSubscription = this.commonMessageTransferService.restAPIGeneralErrorEvent
      .subscribe((error: ErrorDto) => {
        console.log('Got general error', error);
        this.paymentProcessing = false;
        this.errorMessage = error.message;
        this.paymentFailed = true;
      });
    this.processRefereId();  
  }

  async handleSubmit(e, campaignId) {
    e.preventDefault();
    document.querySelector('#submit').setAttribute('disabled', 'disabled');
    await stripe.confirmPayment({
      elements,
      confirmParams: {
      },
      redirect: 'if_required'
    }).then((result)=> {
      document.querySelector('#submit').removeAttribute('disabled');
      if(result.error) {
        if(result.error.type === "card_error" || result.error.type === "validation_error") {
          this.errorMessage = result.error.message;
          this.paymentFailed = true;
        } else {
          this.errorMessage = this.commonBindingService.getLabel('lbl_unexpected_error');
          this.paymentFailed = true;
        }
        
      }else if(result.paymentIntent && result.paymentIntent.status === "succeeded") {
        this.router.navigate(['/paymentStatus/' + this.campaignId], {
          queryParams: {
            userId: this.userId,
            campaignId: this.campaignId,
            mediaType: this.mediaType,
            paymentStatus: true
          }
        });
      }else if(result.paymentIntent && (result.paymentIntent.status === "processing" || result.paymentIntent.status === 'requires_capture')) {
        this.router.navigate(['/paymentStatus/' + this.campaignId], {
          queryParams: {
            userId: this.userId,
            campaignId: this.campaignId,
            mediaType: this.mediaType,
            paymentStatus: 'processing'
          }
        });
      }else {
        this.router.navigate(['/paymentStatus/' + this.campaignId], {
          queryParams: {
            userId: this.userId,
            campaignId: this.campaignId,
            mediaType: this.mediaType,
            paymentStatus: false
          }
        });
      }
    }, (error) => {
      this.errorMessage = this.commonBindingService.getLabel('lbl_unexpected_error');
      this.paymentFailed = true;
    });
    
  }
  
  // Fetches a payment intent and captures the client secret
async initialize() {
  document.querySelector("#payment-form").addEventListener("submit", (event) => this.handleSubmit(event, this.campaignId));
  let payload = {
    'amount': this.payment?.amount,
    'campaignId': this.campaignId,
    'donorName': this.payment?.name,
    'donorMessage': this.payment?.message,
    'donorEmail': this.payment?.email,
    'refereeUserId': this.userId,
    "refererUserId": this.refereId,   //secret
    'serviceFee': parseFloat(this.serviceCharges).toFixed(2),
    "mediaType": this.mediaType
  };
  
  this.commonService.getPaymentIntent(payload).subscribe((res)=> {
    const clientSecret = res.paymentIntent;
    const appearance = {
      theme: 'stripe',
    };

    elements = stripe.elements({ appearance, clientSecret });
    const paymentElementOptions = {
      layout: "tabs",
    };
  
    const paymentElement = elements?.create("payment", {
      paymentElementOptions
    });
    paymentElement?.mount("#payment-element");

    // paymentElement.on('change', (event) => {
    //   if (event.error) {
    //     console.error(event.error.message);
    //   } else {
    //     this.errorMessage = '';
    //     this.paymentFailed = false;
    //   }
    // });

  }, (error)=> {
      console.log(error);
      this.router.navigate(['/donation/' + this.campaignId], {
        queryParams: {
          userId: this.userId,
          mediaType: this.mediaType
        }
      });
  });
}

  ngAfterViewInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.restAPIGeneralSubscription?.unsubscribe();
  }

  onChange({ ...error }) {
    if (error) {
      this.error = error['message'];
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
    console.log("change")
  }

  navigateTostatus() {
    this.router.navigate(['/paymentStatus/' + this.campaignId], {
      queryParams: {
        userId: this.userId,
        campaignId: this.campaignId,
        mediaType: this.mediaType,
        paymentStatus: false
      }
    });
  }

  direct(){ }

  processRefereId() {
    const currentUser = localStorage.getItem("currentUser");
    if(currentUser) {
      let result = currentUser.match(/.{1,16}/g)
      let revFirst = result[0].split("").reverse().join("");
      let revSecond = result[1].split("").reverse().join("");
      this.refereId = (revFirst + revSecond);
    }else {
      this.router.navigate(['/donation/' + this.campaignId], {
            queryParams: {
              userId: this.userId,
              mediaType: this.mediaType
            }
      });
    }
  }

}
