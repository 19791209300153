import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable, of } from 'rxjs';
import { StorageService } from './../../shared/services/storage.service';
import { AppSettings } from './../../shared/app.settings';
import { TranslateService } from '@ngx-translate/core';
import * as moment from "moment";

@Injectable()
export class CommonBindingDataService {

  constructor(private restApiService: RestApiService,
    private storageService: StorageService,
    private translateService: TranslateService,
  ) { }

  buildAwsImgLink(imageName:any) {
    if (imageName) {
      return AppSettings.AWS_BASE_PATH + imageName;
    } else {
      return '/assets/images/profile-placeholder.png';
    }
  }

  buildAwsVideoLink(video:any) {
    if (video) {
      return video;
      // return AppSettings.AWS_VIDEO_BASE_PATH + video;
    } else {
      return 0;
    }
  }


  daysLeft(endDate:any, startDate?:any) {

    endDate= moment.utc(endDate);
    const diff= endDate.diff(moment(),'days');
     if (diff > 0) {
       return diff+ ' days left';
     } else if (diff === 0) {
      return 'Last day';
    } else {
      return 'Closed';
     }


    // startDate = new Date().getTime();
    // const diff = endDate - startDate;
    // const one_day = 1000 * 60 * 60 * 24;
    // if (diff / one_day > 0) {
    //   return Math.ceil(diff / one_day) + ' days left';
    // } else {
    //   return 'Closed';
    // }
  }

  toGMT(now:any) {
    return new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).getTime();
  }

  toLocalTime(miliSeconds:any) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000).getTime();
  }

  toLocalDate(miliSeconds:any) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000);
  }


  unitConversionMToKm(m:any) {
    const results = m / 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionKMToM(km:any) {
    const results = km * 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMinToMiliseconds(min:any) {
    const results = ((min * 60) * 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMilisecondsToMin(mili:any) {
    const results = ((mili / 60) / 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  centerLatLng(dropPoints:any) {
    if (dropPoints.lenght === 0) {
      return 0;
    }
    const latArray = [];
    const lngArray = [];
    for (const points of dropPoints) {
      latArray.push(points.lat);
      lngArray.push(points.lng);
    }
    return this.findCenterDashLatLng(lngArray, latArray);
  }

  findCenterDashLatLng(lngArray:any, latArray:any) {
    const x1 = this.findMinMaxValue('min', lngArray);
    const x2 = this.findMinMaxValue('max', lngArray);
    const y1 = this.findMinMaxValue('min', latArray);
    const y2 = this.findMinMaxValue('max', latArray);
    const centerPoly = {
      lat: y1 + ((y2 - y1) / 2),
      lng: x1 + ((x2 - x1) / 2)
    };
    return centerPoly;
  }

  findMinMaxValue(type:any, data:any) {
    if (type === 'max') {
      return Math.max.apply(null, data);
    } else {
      return Math.min.apply(null, data);
    }
  }


  getSpecificTimeout(code:any, subcode:any) {
    const timeouts = this.storageService.getItem(AppSettings.TIME_SETTINGS);
    if (timeouts !== null) {
      for (const item of timeouts[code]) {
        if (item.key === subcode) {
          return parseInt(item.value, 10);
        }
      }
    }
  }

  getCountries(): Observable<any> {
    return this.restApiService.get('common-get-contries', '/secure/countries', 'page-center');
  }

  getState(id:any): Observable<any> {
    return this.restApiService.get('common-get-states', `/secure/countries/${id}/states`, 'page-center');
  }
  getCitybyCountries(code:any): Observable<any> {
    return this.restApiService.get('common-get-citiesbycountry', '/secure/countries/' + code + '/citiesbycountry', 'page-center');
  }

  getCitybyState(code:any): Observable<any> {
    return this.restApiService.get('common-get-citiesbystate', '/secure/countries/' + code + '/citiesbystate', 'page-center');
  }

  getLabel(string: string) {
    let select;
    this.translateService.get(string).subscribe(translatedValue => {
      select = translatedValue;
    });
    return select;
  }

  calculateDaysLeft(endDate, status?, startDate?) {
    if (status === AppSettings.UNDER_ADMIN_REVIEW || status === AppSettings.UNDER_COACH_REVIEW) {
      return 'Under review';
    }
    if (status === AppSettings.IN_ACTIVE_CAMPAIGN || status === AppSettings.CLOSED_CAMPAIGN) {
      return 'Closed';
    }

    endDate= moment.utc(endDate);
    const diff= endDate.diff(moment(),'days');

    if (diff > 0) {
      return diff+ ' days left';
    } 
    if (diff === 0) {
      return 'Last day';
    }  
    if(diff < 0) {
      return 'Closed';
    }
  }
}
