<div class="grid ui-g-nopad">
    <!-- <div class="ui-g "> -->
      <div class="grid md:col-12 sm:col-12" *ngIf="isPlatformBrowser">
        <h3 class="label" translate>lbl_choose_amout</h3>
        <p-selectButton [options]="denominations" styleClass="donation-form-style" class="red-button" [(ngModel)]="denomination"
          (onChange)="setAmount()"></p-selectButton>
      </div>
    <!-- </div> -->
  <div class="clearfix"></div>
  <form [formGroup]="donationDetailsForm" class="form-width">

    <div class="form-field" [hidden]="!showCustom">
      <div class="form-control">
        <h3 class="label mt-13" translate>lbl_custom_amount</h3>
        <input type="text" formControlName="amount" placeholder="$" class="ui-inputtext" autocomplete="off"
          (keydown)="amountUpdated()" (keyup)="amountUpdated()" pInputText />
      </div>
    </div>
    <div class="form-errors mt-2"
      *ngIf="donationDetailsForm.controls.amount.errors  && (donationDetailsForm.controls.amount.dirty || donationDetailsForm.controls.amount.touched)">
      <div [hidden]="!donationDetailsForm.controls.amount.errors.required" translate>
        err_amount_req
      </div>
      <div [hidden]="!donationDetailsForm.controls.amount.errors.min" translate>
        err_amount_invalid
      </div>
      <div [hidden]="!donationDetailsForm.controls.amount.errors.pattern" translate>
        err_custom_amount_invalid
      </div>
    </div>


    <div class="form-field mt-13" >
      <div class="form-control">
        <p class="label-charges" translate>lbl_cover_fees</p>
        <p-checkbox  [binary]="true" [(ngModel)]="additionalCharges" [ngModelOptions]="{standalone: true}" [disabled]="checkboxDisabled" (onChange)="coverChargesSelected($event)"> 
        </p-checkbox>
        <span class="label ml-10" translate>lbl_additional_charges <span *ngIf="checkboxDisabled === false"> ${{ totalDenominationCharges | number:'1.2-2' }}</span></span>
        <p class="label" translate *ngIf="checkboxDisabled === false && showCheckbox === false && donationDetailsForm.controls.amount.value !='' ">lbl_total_donation <span>${{ totalDenominationAmount | number:'1.2-2' }}</span></p>
        <p class="label" translate *ngIf="showCheckbox === true && donationDetailsForm.controls.amount.value !='' ">lbl_total_donation <span>${{ denomination | number:'1.2-2' }}</span></p> 
      </div>
    </div>


    <div class="form-field">
      <div class="form-control">
        <h3 class="label" translate>lbl_message</h3>
        <textarea [rows]="5" [cols]="30" formControlName="message" pInputTextarea autoResize="autoResize"
          class="ui-inputtextarea"></textarea>
      </div>
    </div>
    <div class="form-errors"
      *ngIf="donationDetailsForm.controls.message.errors  && (donationDetailsForm.controls.message.dirty || donationDetailsForm.controls.message.touched)">
      <div [hidden]="!donationDetailsForm.controls.message.errors.maxlength" translate>
        err_message_length
      </div>
    </div>



    <div class="form-field mt-13" *ngIf="isPlatformBrowser">
      <div class="form-control">
        <p-checkbox [(ngModel)]="anonymous" [binary]="true" [ngModelOptions]="{standalone: true}"
          (onChange)="onAnonymousSelected($event)">
        </p-checkbox><span class="label ml-10" translate>lbl_anonymous</span>
      </div>
    </div>

    <div class="form-field" [hidden]="anonymous">
      <div class="form-control">
        <h3 class="label" translate> lbl_name<span>*</span></h3>
        <input type="text" formControlName="name" class="ui-inputtext" autocomplete="off" pInputText />
      </div>
    </div>
    <div class="form-errors mt-2"
      *ngIf="donationDetailsForm.controls.name.errors  && (donationDetailsForm.controls.name.dirty || donationDetailsForm.controls.name.touched)">
      <div [hidden]="!donationDetailsForm.controls.name.errors.required" translate>
        err_name_req
      </div>
    </div>
    <div class="form-field" [hidden]="anonymous">
      <div class="form-control">
        <h3 class="label" translate> lbl_phone_no<span>*</span></h3>
        <p-inputMask type="text" formControlName="donorContactNumber" [autoClear]="false" class="ui-inputtext"
            mask="(999) 999-9999" [unmask]="true" ></p-inputMask>
      </div>
    </div>
    <div class="form-errors mt-2"
      *ngIf="donationDetailsForm.controls.donorContactNumber.errors  && (donationDetailsForm.controls.donorContactNumber.dirty || donationDetailsForm.controls.donorContactNumber.touched)">
      <div [hidden]="!donationDetailsForm.controls.donorContactNumber.errors.required" translate>
        err_phone_no_req
      </div>
      <div [hidden]="!donationDetailsForm.controls.donorContactNumber.errors.minlength" translate>
        err_phone_no_invalid
      </div>
    </div>
    <div class="form-field">
      <div class="form-control">
        <h3 class="label" translate>lbl_email</h3>
        <input type="text" formControlName="email" class="ui-inputtext" pInputText />
      </div>
    </div>
    <div class="form-errors mt-2"
      *ngIf="donationDetailsForm.controls.email.errors  && (donationDetailsForm.controls.email.dirty || donationDetailsForm.controls.email.touched)">
      <div [hidden]="!donationDetailsForm.controls.email.errors.pattern" translate>
        err_email_invalid
      </div>
      <!-- <div [hidden]="!donationDetailsForm.controls.email.errors.required" translate>
        err_email_required
      </div> -->
    </div>
    <p class="text-and-link">For receipt purposes only. We will never sell or share your email.</p>
      <div class="text-center">
        <button class="theme-btn donate-btn" [disabled]="!donationDetailsForm.valid" type="submit"
          (click)="onDonate()">Donate</button>
      </div>
  </form>
</div>